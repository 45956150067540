import React from 'react'
import AboutUsImg from '../../assets/about_us.png'
import { BsFillArrowRightSquareFill } from 'react-icons/bs'

const About = () => {
    return (
        <div className='w-full flex max-sm:flex max-sm:flex-col items-center justify-center gap-8 max-sm:gap-10 p-20 max-sm:px-2'>
            {/* left */}
            <div className='w-1/2 max-sm:w-full mt-20'>
                <img src="https://cdn.pixabay.com/photo/2020/02/27/14/33/building-4884852_1280.jpg" alt="" className='transform filter drop-shadow-2xl rounded-lg' />
            </div>

            {/* right */}
            <div className='w-1/2 max-sm:w-full flex flex-col justify-center gap-5'>
                <h1 className='text-5xl max-lg:text-3xl font-bold max-sm:text-center'>Why NeuralTechAnalytics</h1>
                <div className='flex items-start gap-2 max-sm:text-sm max-lg:text-sm bg-black p-3 rounded-lg shadow-md shadow-[#130D0F]'>
                    <span className='pt-1 max-sm:text-xs text-white'>
                        <BsFillArrowRightSquareFill />
                    </span>

                    <p className='text-gray-200 font-bold'>Originating as a direct response to firsthand encounters with inefficiencies in the retail industry.</p>
                </div>
                <div className='flex items-start gap-2 max-sm:text-sm max-lg:text-xs bg-black p-3 rounded-lg shadow-md shadow-[#130D0F]'>
                    <span className='pt-1 max-sm:text-xs text-white'>
                        <BsFillArrowRightSquareFill />
                    </span>

                    <p className='text-gray-200 font-bold'>Delivering pertinent performance KPIs customized to meet specific business needs, enabling quick, efficient, and accurate strategic decision-making at your fingertips.</p>
                </div>
                <div className='flex items-start gap-2 max-sm:text-sm max-lg:text-xs bg-black p-3 rounded-lg shadow-md shadow-[#130D0F]'>
                    <span className='pt-1 max-sm:text-xs text-white'>
                        <BsFillArrowRightSquareFill />
                    </span>

                    <p className='text-gray-200 font-bold'>Dashboards to review business performance and take actions right from store manager till CEO.</p>
                </div>
                <div className='flex items-start gap-2 max-sm:text-sm max-lg:text-xs bg-black p-3 rounded-lg shadow-md shadow-[#130D0F]'>
                    <span className='pt-1 max-sm:text-xs text-white'>
                        <BsFillArrowRightSquareFill />
                    </span>

                    <p className='text-gray-200 font-bold'>Need for streamlined, demand-driven merchandising.</p>
                </div>
                <div className='flex items-start gap-2 max-sm:text-sm max-lg:text-xs bg-black p-3 rounded-lg shadow-md shadow-[#130D0F]'>
                    <span className='pt-1 max-sm:text-xs text-white'>
                        <BsFillArrowRightSquareFill />
                    </span>

                    <p className='text-gray-200 font-bold'>Transitioning a retailer from a stagnant setting to a dynamically scalable environment.</p>
                </div>
            </div>
        </div>
    )
}

export default About