import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import Button from '../../../customcomponents/Button/Button';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseSharp } from "react-icons/io5";

const NavbarNew = () => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };
    return (
        <div className='w-full fixed top-0 z-10'>
            <div className="w-full p-1 flex items-end justify-end gap-5 text-bold mt-0 bg-[#1f1e1e] max-lg:text-sm">
                {/* <Link
                    to="/requestademo"
                    className="block md:inline-block px-3 py-2 border-1 bg-blue-600 border-blue-400 font-[600] rounded-full hover:bg-white hover:text-black duration-300"
                >
                    Request a Demo
                </Link> */}
                {/* <Link
                    to="/requestademo"
                    className="px-5 py-3 rounded-full relative bg-slate-600 text-white text-sm hover:bg-slate-500 hover:shadow-2xl hover:shadow-white/[0.2] transition duration-300 border border-slate-600">
                    <div className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl  bg-gradient-to-r from-transparent via-teal-500 to-transparent" />
                    <span className="relative z-20 font-[600]">
                        Request a Demo
                    </span>
                </Link> */}
                <Link to="/requestademo">
                    <Button
                        btnText="Request a Demo"
                        btnTextColor="text-white"
                        btnBgColor="bg-slate-600"
                        btnBgColorHover="hover:bg-slate-500"
                        btnPadding="px-5 py-3"
                    />
                </Link>
                <Link
                    to="/support"
                    className="block md:inline-block text-gray-400 hover:text-white duration-300 px-3 py-3 border-b-2 border-blue-900 md:border-none font-[600]"
                >
                    Support
                </Link>
                <Link
                    to="/"
                    className="block md:inline-block text-gray-400 hover:text-white duration-300 px-3 py-3 border-b-2 border-blue-900 md:border-none font-[600]"
                >
                    Login
                </Link>
            </div>
            <nav className="w-full bg-black text-white px-10 shadow-2xl shadow-gray-950">
                <div className="flex items-center justify-between">
                    <div className="z-50 p-5 md:w-auto w-full flex justify-between">
                        {/* <img src={Logo} alt="logo" className="md:cursor-pointer h-9" /> */}
                        <Link to='/'>
                            <h1 className='text-2xl font-bold tracking-widest'>nta</h1>
                        </Link>
                        <div className="text-3xl md:hidden" onClick={toggleMenu}>
                            {
                                open ? <IoCloseSharp className='text-white' /> : <RxHamburgerMenu className='text-white' />
                            }
                        </div>
                    </div>
                    <ul className="md:flex hidden uppercase items-center gap-8 max-lg:gap-4 max-lg:text-sm font-[Poppins] font-bold">
                        <li>
                            <Link to="/" className="py-7 px-3 inline-block">
                                Home
                            </Link>
                        </li>
                        <NavLinks activeMenu={toggleMenu} />
                        <li>
                            <Link to="/customers" className="py-7 px-3 inline-block">
                                Customers
                            </Link>
                        </li>
                    </ul>

                    {/* Mobile nav */}
                    <ul
                        className={`md:hidden bg-black text-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4 duration-500 ${open ? "left-0" : "left-[-100%]"}`}
                    >
                        <li>
                            <Link to="/" className="py-7 px-3 inline-block">
                                Home
                            </Link>
                        </li>
                        <NavLinks activeMenu={toggleMenu} />
                        <li>
                            <Link to="/customers" className="py-7 px-3 inline-block">
                                Customers
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default NavbarNew